import { css } from "linaria";

export const Wrapper = css`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export const Select = css`
  flex: 1 1 auto;
`;

export const Button = css`
  flex: 0 0 auto;
  border: none;
  padding: 0 0.5em;
  cursor: pointer;
  color: var(--foreground);
  background: var(--primary);
`;
