import type { VDom } from "~/typings";

import * as S from "./Progress.styles";

type Props = {
  value: number;
  label: VDom.Node;
};

const Progress = ({ value, label }: Props) => {
  const content = { width: `${100 * value}%` };
  const tooltip = { left: `${100 * value}%`, transform: `translateX(${-100 * value}%)` };
  const arrow = { left: `calc((100% - 10px) * ${value} + 5px)` };

  return (
    <div className={S.Wrapper}>
      <div className={S.Content} style={content} />
      <div className={S.Tooltip} style={tooltip}>
        <div className={S.Arrow} style={arrow} />
        <div className={S.Label}>{label}</div>
      </div>
    </div>
  );
};

export { Progress };
