import classNames from "classnames";

import type { VDom } from "~/typings";

import * as S from "./Button.styles";

type Props = VDom.WithChildren<{
  size?: "normal" | "large";
  mode?: "primary" | "secondary";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}>;

/**
 * The basic button component, with several possible state :
 * - size : normal or large
 * - mode : primary or secondary
 */
const Button = (props: Props) => {
  const { disabled, children, onClick } = props;
  const { size = "normal", mode = "primary" } = props;
  const className = classNames(S.Base, S.Size[size], S.Mode[mode], props.className);

  return (
    <button disabled={disabled} type="button" className={className} onClick={() => onClick?.()}>
      {children}
    </button>
  );
};

export { Button };
