import { useState } from "react";

import Keyboard from "~/assets/emojis/keyboard.svg";
import Enter from "~/assets/icons/enter.svg";
import { Button, Header, Select } from "~/components";
import type { Entity } from "~/typings";

import * as S from "./SimpleInput.styles";

type Props = {
  store: Entity.Store;
  onSelect: (container: Entity.Container) => void;
};

const SimpleInput = ({ store, onSelect }: Props) => {
  const { orders, containers } = store;
  const [value, setValue] = useState("");

  return (
    <>
      <Header
        icon={<Keyboard />}
        title="Entrez la référence d’un bac"
        subtitle={
          <div className={S.Wrapper}>
            <Select value={value} onChange={setValue} className={S.Select}>
              <option value="">---</option>
              {orders.map((order) => (
                <optgroup key={order.id} label={`Commande ${order.ref}`}>
                  {order.containers.map((container) => (
                    <option key={container.id} value={container.id} disabled={container.collected}>
                      {`Emplacement ${container.location}`}
                      {` — `}
                      {`Bac n° ${container.ref}`}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Select>
            <Button
              disabled={!value}
              onClick={() => {
                const container = containers.find((c) => c.id === value);
                if (container != null) onSelect(container);
                setValue("");
              }}
            >
              <Enter />
            </Button>
          </div>
        }
      />
    </>
  );
};

export { SimpleInput };
