import { css } from "linaria";

export const Wrapper = css`
  margin: 0 20px;
  padding: 0;
`;

export const Icon = css`
  margin: 20px 0 0 0;

  svg {
    display: block;
    width: 64px;
    height: 64px;
  }
`;

export const Title = css`
  margin: 20px 0 0 0;
  font-size: 34px;
  line-height: 34px;
  font-weight: normal;
`;

export const Subtitle = css`
  margin: 20px 0 0 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;

  svg {
    transform: translateY(0.125em);
  }
`;

export const Description = css`
  margin: 10px 0 0 0;
  font-size: 18px;
  line-height: 18px;
`;
