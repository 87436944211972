import { Settings } from "~/settings";

/**
 * Media constraints for back-facing camera access
 * Giving width or height cause Safari 12 to crash.
 */
const constraints: MediaStreamConstraints = {
  audio: false,
  video: {
    frameRate: 60,
    facingMode: "environment",
    width: { ideal: Settings.Camera.width },
    height: { ideal: Settings.Camera.height },
    aspectRatio: { ideal: Settings.Camera.width / Settings.Camera.height },
  },
};

/**
 * Simple wrapper around the MediaDevices API that will resolve
 * to a MediaStream that matches the application requirement
 */
const getUserMediaStream = () => {
  return navigator.mediaDevices.getUserMedia(constraints);
};

export { getUserMediaStream };
