import { useState, useEffect } from "react";

import { getUserMediaStream } from "~/utils";

/**
 * Request access to the back camera and return the corresponding
 * media stream, or the rejection error if access is rejected.
 */
const useUserMediaStream = () => {
  type State = { stream: MediaStream | null; error: Error | null };
  const [state, setState] = useState<State>({ stream: null, error: null });

  useEffect(() => {
    getUserMediaStream()
      .then((stream) => void setState({ stream, error: null }))
      .catch((error: Error) => void setState({ stream: null, error }));
  }, []);

  useEffect(() => {
    return () => state.stream?.getTracks().forEach((track) => track.stop());
  }, [state]);

  return state;
};

export { useUserMediaStream };
