import { Settings } from "~/settings";
import type { Barcode } from "~/typings";

// Since the feature is still in draft, official typings are not available
// The following is a very small subset of the current implementation
// https://wicg.github.io/shape-detection-api/#barcode-detection-api
type Format = "code_128" | "qr_code";
type Result = { format: Format; rawValue: string };
declare const BarcodeDetector: {
  getSupportedFormats: () => Promise<string[]>;
  new (params: { formats: Format[] }): {
    detect(data: ImageData): Promise<Result[]>;
  };
};

/**
 * This module uses the native BarcodeDetector API
 * Extremely fast, but support is very limited
 */
const NativeBarcodeService: Barcode.Service = {
  name: "Native",
  isEnabled: () => {
    return Settings.Decoder.native;
  },
  isAvailable: async () => {
    if (!("BarcodeDetector" in window)) return false;
    const supported = await BarcodeDetector.getSupportedFormats();
    return supported.includes("qr_code") && supported.includes("code_128");
  },
  loadScanner: () => {
    // Initialize scanner for QRCode and Code128
    const reader = new BarcodeDetector({
      formats: ["qr_code", "code_128"],
    });
    // Normalize format name
    const getBarcodeFormat = (value: string): Barcode.Format => {
      if (value === "code_128") return "Code128";
      if (value === "qr_code") return "QRCode";
      return "Unknown";
    };
    // Generate scanner function
    return async (data: ImageData) => {
      const results = await reader.detect(data);
      return results.map((result) => {
        const format = getBarcodeFormat(result.format);
        const value = result.rawValue;
        return { format, value };
      });
    };
  },
};

export { NativeBarcodeService };
