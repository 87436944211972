import { css } from "linaria";

export const Row = css`
  border-top: 1px dashed var(--neutral);
`;

export const Separator = css`
  border-top: 1px solid var(--neutral);
`;

export const Icon = css`
  margin: 0 8px 0 0;
  font-size: 16px;
`;

export const IconStatus = css`
  margin: 0 0 0 12px;
  transform: scale(1.5);
`;
