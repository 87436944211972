import { useEffect, useState } from "react";

import { SimpleInput, Progress, CameraInput, ContainerList, Toaster, Sticky } from "~/components";
import { Settings } from "~/settings";
import type { Barcode, Entity } from "~/typings";
import { getContainersFromCode128, getContainersFromQRCode } from "~/utils";

type Props = {
  store: Entity.Store;
  sortMode: Entity.SortMode;
  onClose: () => void;
  onCollect: (values: string[]) => void;
  setSortMode: (mode: Entity.SortMode) => void;
};

const StepOngoing = ({ store, sortMode, onClose, onCollect, setSortMode }: Props) => {
  const { containers } = store;
  const countTotal = containers.length;
  const countCollected = containers.filter((c) => c.collected).length;

  // Handle error toaster visibility
  const [isErrorToasterVisible, setErrorToasterVisible] = useState(false);

  // Scroll to top on mount
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  // Parse barcode results and map to containers
  const handleResults = (results: Barcode.Result[]) => {
    const matches: Entity.Container[] = [];
    results.forEach(({ format, value }) => {
      if (format === "QRCode") matches.push(...getContainersFromQRCode(containers, value));
      if (format === "Code128") matches.push(...getContainersFromCode128(containers, value));
    });
    return matches;
  };

  // Handle the result of a manual input or scan
  const handleContainers = (items: Entity.Container[]) => {
    // Separate newly and already scanned containers
    const newContainers: string[] = [];
    const oldContainers: string[] = [];
    items.forEach(({ id, collected }) => {
      void (collected ? oldContainers : newContainers).push(id);
    });

    // Handle results
    if (newContainers.length > 0) return void onCollect(newContainers);
    if (oldContainers.length > 0) return;
    setErrorToasterVisible(true);
  };

  return (
    <>
      <Sticky mode="top">
        {Settings.Browser.type === "desktop" ? (
          <SimpleInput store={store} onSelect={(item) => void handleContainers([item])} />
        ) : (
          <>
            <CameraInput
              title="Scannez un bac"
              onClose={onClose}
              onResults={(results) => void handleContainers(handleResults(results))}
            />
            <Progress
              value={countCollected / countTotal}
              label={`${countCollected}/${countTotal}`}
            />
          </>
        )}
      </Sticky>
      <ContainerList
        viewMode="hide"
        sortMode={sortMode}
        containers={containers}
        setSortMode={setSortMode}
      />
      <Toaster
        className="invalid-bg"
        visible={isErrorToasterVisible}
        onClose={() => setErrorToasterVisible(false)}
      >
        Repose ce bac, il est pas pour toi ! 😡
      </Toaster>
    </>
  );
};

export { StepOngoing };
