import type { VDom } from "~/typings";

import * as S from "./Header.styles";
type Props = {
  icon?: VDom.Node;
  title?: VDom.Node;
  subtitle?: VDom.Node;
  description?: VDom.Node;
  className?: string;
};

/**
 * Display an icon, title, subtitle and description
 * This component provides normalized page headers.
 */
const Header = ({ icon, title, subtitle, description, className = "" }: Props) => (
  <div className={`${S.Wrapper} ${className}`}>
    {icon == null ? null : <div className={S.Icon}>{icon}</div>}
    {title == null ? null : <h1 className={S.Title}>{title}</h1>}
    {subtitle == null ? null : <h2 className={S.Subtitle}>{subtitle}</h2>}
    {description == null ? null : <p className={S.Description}>{description}</p>}
  </div>
);

export { Header };
