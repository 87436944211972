import { useErrorBoundary } from "preact/hooks";
import { Redirect, Route, Switch } from "wouter";

import { Home, Collect } from "~/screens";
import { Logger } from "~/services";

import { Settings } from "./settings";

const App = () => {
  useErrorBoundary((error) => void (error && Logger.error(error)));

  // This is required since iOS does not allow any browser
  // except Safari to have access to the camera api.
  const isIOS = Settings.Browser.os === "iOS";
  const isSafari = Settings.Browser.browser === "Safari";
  if (isIOS && !isSafari) return <h1>Safari requis sous iOS</h1>;

  return (
    <Switch>
      <Route<{ token: string }> path="/collect/:token">
        {(params) => <Collect token={params.token} />}
      </Route>
      <Route<{ token: string }> path="/courses/:token">
        {(params) => <Redirect to={`/collect/${params.token}`} />}
      </Route>
      <Route>{() => <Home />}</Route>
    </Switch>
  );
};

export { App };
