import TimerClock from "~/assets/emojis/timer-clock.svg";
import ClockReload from "~/assets/icons/clock-reload.svg";
import { Button, Header, Spacer, OrderList } from "~/components";
import type { Entity } from "~/typings";

type Props = {
  store: Entity.Store;
  reload: () => void;
};

const StepPreparing = ({ store, reload }: Props) => {
  const { orders } = store;

  return (
    <>
      <Header
        icon={<TimerClock />}
        title="Encore un peu de patience !"
        subtitle="Les bacs ne sont pas prêts à être récupérés."
      />
      <hr />
      <OrderList orders={orders} showStatus />
      <Spacer />
      <Button size="large" mode="secondary" onClick={() => reload()}>
        <span>Rafraichir</span>
        <ClockReload />
      </Button>
    </>
  );
};

export { StepPreparing };
