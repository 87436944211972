import SignOfTheHorns from "~/assets/emojis/sign-of-the-horns.svg";
import ThumbsUp from "~/assets/emojis/thumbs-up.svg";
import Arrow from "~/assets/icons/arrow.svg";
import { Button, Header, Spacer, OrderList } from "~/components";
import { Settings } from "~/settings";
import type { Entity } from "~/typings";

type Props = {
  store: Entity.Store;
};

const StepCompleted = ({ store }: Props) => {
  const { orders, deliveryMode } = store;

  return (
    <>
      <Header
        icon={<ThumbsUp />}
        title="Parfait !"
        subtitle={
          <>
            Tous les bacs ont été récupérés.
            <br />
            <span className="primary-fg">Bonne livraison ! </span>
            <SignOfTheHorns />
          </>
        }
      />
      <hr />
      <OrderList orders={orders} showContainers />
      <Spacer />
      {deliveryMode === "OnFleet" && Settings.Browser.type === "mobile" ? (
        <Button size="large" onClick={() => window.open("onfleet://tasks", "_system")}>
          <span>Retour sur OnFleet</span>
          <Arrow />
        </Button>
      ) : null}
    </>
  );
};

export { StepCompleted };
