/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { Entity } from "~/typings";

/** Return all containers that match the given QRCode */
const getContainersFromQRCode = (items: Entity.Container[], data: string) => {
  try {
    const parsed = JSON.parse(data);
    const { type, payload } = parsed;
    const { pRf, cId, cRf } = payload;
    return items.filter((container) => {
      if (type !== "Ppb") return false;
      const isCorrectCID = container.id === cId;
      const isCorrectCRF = container.ref === cRf;
      const isCorrectPRF = container.order.ref === pRf;
      return isCorrectPRF && isCorrectCID && isCorrectCRF;
    });
  } catch {
    return [];
  }
};

export { getContainersFromQRCode };
