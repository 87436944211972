import { NativeBarcodeService, WasmBarcodeService, VanillaBarcodeService } from "~/services";

/** List of services in order of preference */
const services = [NativeBarcodeService, WasmBarcodeService, VanillaBarcodeService];

/**
 * This function will iterate all the available services, and will return the
 * first that is enabled and compatible with the system running the application.
 * Throw an error if no compatible service is found. Shouldn't happen though.
 */
const getBarcodeService = async () => {
  const promises = services.map(async (service) => {
    const available = await service.isAvailable();
    const enabled = await service.isEnabled();
    const useable = available && enabled;
    return { service, useable };
  });
  const resolved = await Promise.all(promises);
  const matching = resolved.find((v) => v.useable);

  if (matching != null) return matching.service;
  throw new Error("No compatible service found");
};

export { getBarcodeService };
