import { useEffect, useRef } from "react";

import classNames from "classnames";

import type { VDom } from "~/typings";

import * as S from "./Toaster.styles";

type Props = VDom.WithChildren<{
  visible: boolean;
  onClose: () => void;
  timeout?: number;
  className?: string;
}>;

const Toaster = ({ visible, timeout, children, className, onClose }: Props) => {
  const timeoutRef = Object.assign(useRef(timeout), { current: timeout });
  const onCloseRef = Object.assign(useRef(onClose), { current: onClose });

  useEffect(() => {
    if (!visible) return;
    const timeoutValue = timeoutRef.current ?? 2000;
    const onCloseValue = onCloseRef.current ?? (() => null);
    console.log(timeoutValue, onCloseValue);
    const timer = setTimeout(() => onCloseValue(), timeoutValue);
    return () => clearInterval(timer);
  }, [visible, onCloseRef, timeoutRef]);

  return (
    <div className={classNames(S.Wrapper, { [S.Visible]: visible })}>
      <div className={`${S.Content} ${className ?? ""}`}>{children}</div>
    </div>
  );
};

export { Toaster };
