import { css } from "linaria";

export const Wrapper = css`
  margin: 0 20px;
  padding: 0;
`;

export const Table = css`
  width: 100%;
  margin: 30px 0 0 0;
`;

export const Body = css`
  overflow: auto;

  td {
    height: 36px;
    font-size: 18px;
    white-space: nowrap;

    &:first-child {
      width: 100%;
    }

    & + td {
      padding-left: 8px;
    }
  }
`;
