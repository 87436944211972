import { useCallback, useState } from "react";

import type { Entity } from "~/typings";

import { StepCompleted, StepOngoing, StepPreparing, StepWaiting } from "./Steps";

type Props = {
  store: Entity.Store;
  sortMode: Entity.SortMode;
  reload: () => void;
  collect: (ids: string[]) => void;
  setSortMode: (mode: Entity.SortMode) => void;
};

const CollectProcessing = ({ store, sortMode, reload, collect, setSortMode }: Props) => {
  // Prepare user status and tasks flags
  const [isWaiting, setWaiting] = useState(true);
  const isCompleted = store.containers.every((c) => c.collected);
  const isPreparing = store.orders.some((o) => o.state === "IN_PROGRESS");

  // Handle start and pause
  const handlePause = useCallback(() => setWaiting(true), []);
  const handleStart = useCallback(() => setWaiting(false), []);

  // Handle all possible status
  if (isPreparing) return <StepPreparing store={store} reload={reload} />;
  if (isCompleted) return <StepCompleted store={store} />;
  return isWaiting ? (
    <StepWaiting
      store={store}
      sortMode={sortMode}
      onStart={handleStart}
      setSortMode={setSortMode}
    />
  ) : (
    <StepOngoing
      store={store}
      sortMode={sortMode}
      onCollect={collect}
      onClose={handlePause}
      setSortMode={setSortMode}
    />
  );
};

export { CollectProcessing };
