import { css } from "linaria";

export const Wrapper = css`
  margin: 0 20px;
  padding: 0;
`;

export const Title = css`
  font-size: 16px;
  font-weight: 300;
  margin: 20px 0 0 0;
`;

export const Table = css`
  width: 100%;
  margin: 10px 0 0 0;
  border-collapse: collapse;
`;

export const Head = css`
  tr {
    border-top: 1px solid var(--foreground);
    border-bottom: 1px solid var(--foreground);
  }

  th {
    padding: 15px 0 15px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;

    &:last-child {
      text-align: right;
    }
  }
`;

export const Body = css`
  overflow: auto;

  tr:first-child {
    border-top: none;
  }

  td {
    padding: 15px 0;
    font-size: 24px;

    &:last-child {
      text-align: right;
    }
  }
`;

export const Button = css`
  cursor: pointer;

  &::after {
    content: " ↓ ";
    visibility: hidden;
  }

  &:last-child {
    &::after {
      content: none;
    }
    &::before {
      content: " ↓ ";
      visibility: hidden;
    }
  }
`;

export const Active = css`
  color: var(--primary);

  &&::before {
    content: none;
  }
  &&:after {
    content: " ↓ ";
    visibility: visible;
  }
`;
