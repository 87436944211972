import { useState } from "react";

import BoxNormal from "~/assets/icons/box-normal.svg";
import Chevron from "~/assets/icons/chevron.svg";
import ShoppingCart from "~/assets/icons/shopping-cart.svg";
import SuccessCircle from "~/assets/icons/success-circle.svg";
import type { Entity } from "~/typings";
import { pluralize } from "~/utils";

import * as S from "./OrderRow.styles";

type Props = {
  order: Entity.Order;
  showStatus?: boolean;
  showContainers?: boolean;
};

const OrderRow = ({ order, showStatus, showContainers }: Props) => {
  const [isVisible, setVisible] = useState(false);
  const containers = order.containers;
  const isCompleted = containers.every((container) => container.collected);

  return (
    <>
      <tr onClick={() => setVisible((v) => !v)}>
        <td>
          {isCompleted ? (
            <SuccessCircle className={`${S.Icon} success-fg`} />
          ) : (
            <ShoppingCart className={`${S.Icon} primary-fg`} />
          )}
          <span>Commande n°{order.ref}</span>
        </td>
        <td>
          {showStatus ? (
            <>
              {order.state === "IN_PROGRESS" && <span className={S.Tag}>En préparation</span>}
              {order.state === "READY" && <span className={`${S.Tag} success-bg`}>Prête</span>}
            </>
          ) : (
            <>
              <BoxNormal className={`${S.Icon} primary-fg`} />
              <span>
                {containers.length} {pluralize("bac", containers.length)}
              </span>
            </>
          )}
        </td>
        {showContainers ? (
          <td>
            <Chevron
              className={`${S.Icon} ${S.IconToggle}`}
              style={{ transform: `scaleY(${isVisible ? -1 : 1})` }}
            />
          </td>
        ) : null}
      </tr>
      {showContainers && isVisible ? (
        <tr>
          <td colSpan={3}>
            <div className={S.Label}>{pluralize(["Bac n°", "Bacs n°"], containers.length)}</div>
            <div className={S.Details}>
              {containers.map(({ id, ref }) => (
                <div key={id}>{ref}</div>
              ))}
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export { OrderRow };
