import type { Entity } from "~/typings";

import * as S from "./OrderList.styles";
import { OrderRow } from "./OrderRow";

type Props = {
  orders: Entity.Order[];
  showStatus?: boolean;
  showContainers?: boolean;
};

const OrderList = ({ orders, showStatus, showContainers }: Props) => {
  return (
    <div className={S.Wrapper}>
      <table className={S.Table}>
        <tbody className={S.Body}>
          {orders.map((order) => (
            <OrderRow
              key={order.id}
              order={order}
              showStatus={showStatus}
              showContainers={showContainers}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { OrderList };
