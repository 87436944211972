if (Constants.isLocal) require("preact/debug");

import "~/services/Sentry";
import "~/services/Workbox";

import ReactDOM from "react-dom";

import { App } from "~/App";
import { Theming } from "~/services";

ReactDOM.render(<App />, Theming.getRoot());
