import HourglassNotDone from "~/assets/emojis/hourglass-not-done.svg";
import Keplr from "~/assets/keplr.svg";

import * as S from "./Loader.styles";

/**
 * A full screen loader with no determinate state
 */
const Loader = () => {
  return (
    <div className={S.Wrapper}>
      <HourglassNotDone className={S.Icon} />
      <div className={S.Dots}>
        <div className={S.Dot} />
        <div className={S.Dot} />
        <div className={S.Dot} />
      </div>
      <Keplr className={S.Keplr} />
    </div>
  );
};

export { Loader };
