import classNames from "classnames";

import type { Entity } from "~/typings";

import * as S from "./ContainerList.styles";
import { ContainerRow } from "./ContainerRow";

type Props = {
  viewMode: "none" | "hide" | "info";
  sortMode: Entity.SortMode;
  containers: Entity.Container[];
  setSortMode: (mode: Entity.SortMode) => void;
};

const ContainerList = ({ viewMode, sortMode, containers, setSortMode }: Props) => {
  const items = containers.filter((container) => {
    if (viewMode !== "hide") return true;
    return !container.collected;
  });

  return (
    <div className={S.Wrapper}>
      <div className={S.Title}>Trier par</div>
      <table className={S.Table}>
        <thead className={S.Head}>
          <tr>
            <th
              onClick={() => setSortMode("location")}
              className={classNames(S.Button, { [S.Active]: sortMode === "location" })}
            >
              Emplacement
            </th>
            <th
              onClick={() => setSortMode("container")}
              className={classNames(S.Button, { [S.Active]: sortMode === "container" })}
            >
              Bac n°
            </th>
            <th
              onClick={() => setSortMode("order")}
              className={classNames(S.Button, { [S.Active]: sortMode === "order" })}
            >
              Commande
            </th>
          </tr>
        </thead>
        <tbody className={S.Body}>
          {items.map((container, i) => (
            <ContainerRow
              key={container.id}
              container={container}
              showStatus={viewMode === "info"}
              showSeparator={
                (sortMode === "order" && container.order.id !== items[i - 1]?.order.id) ||
                (sortMode === "location" && container.location !== items[i - 1]?.location) ||
                (sortMode === "container" && container.ref !== items[i - 1]?.ref)
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { ContainerList };
