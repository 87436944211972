import { css } from "linaria";

export const Wrapper = css`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Icon = css`
  margin-top: auto;
  align-self: center;
  width: 100px;
  height: 100px;
`;

export const Dots = css`
  margin-top: 30px;
  align-self: center;
  display: flex;
`;

export const Dot = css`
  margin: 0 6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: var(--foreground);
  animation: 1.2s blink infinite both;

  &:nth-child(2) {
    animation-delay: 200ms;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

export const Keplr = css`
  margin-top: auto;
  margin-bottom: 30px;
  align-self: center;
  width: 100px;
  height: auto;
`;
