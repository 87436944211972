import { Settings } from "~/settings";
import type { Barcode } from "~/typings";

/**
 * WebAssembly implementation using ZBar
 * Fast, with very decent support
 */
const WasmBarcodeService: Barcode.Service = {
  name: "WebAssembly",
  isEnabled: () => {
    return Settings.Decoder.wasm;
  },
  isAvailable: () => {
    if (typeof WebAssembly !== "object") return false;
    if (typeof WebAssembly.instantiate !== "function") return false;
    return true;
  },
  loadScanner: async () => {
    // Import module and enums
    const ZBar = await import("zbar.wasm");
    const Enums = await import("zbar.wasm/dist/enum");
    // Initialize scanner for QRCode and Code128
    const settings = await ZBar.getDefaultScanner();
    settings.setConfig(Enums.ZBarSymbolType.ZBAR_NONE, Enums.ZBarConfigType.ZBAR_CFG_ENABLE, 1);
    settings.setConfig(Enums.ZBarSymbolType.ZBAR_QRCODE, Enums.ZBarConfigType.ZBAR_CFG_ENABLE, 1);
    settings.setConfig(Enums.ZBarSymbolType.ZBAR_CODE128, Enums.ZBarConfigType.ZBAR_CFG_ENABLE, 1);
    // Normalize format name
    const getBarcodeFormat = (value: number): Barcode.Format => {
      if (value === Enums.ZBarSymbolType.ZBAR_CODE128) return "Code128";
      if (value === Enums.ZBarSymbolType.ZBAR_QRCODE) return "QRCode";
      return "Unknown";
    };
    // Generate scanner function
    return async (data: ImageData) => {
      const results = await ZBar.scanImageData(data);
      return results.map((result) => {
        const format = getBarcodeFormat(result.type);
        const value = result.decode();
        return { format, value };
      });
    };
  },
};

export { WasmBarcodeService };
