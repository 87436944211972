if ("serviceWorker" in navigator)
  void (async () => {
    // Initialize Workbox and force a reload on app update
    const { Workbox } = await import("workbox-window");
    const workbox = new Workbox("/service-worker.js");
    workbox.addEventListener("installed", (event) => {
      if (event.isUpdate) window.location.reload();
    });
    workbox.register().catch(() => null);
  })();

export {};
