import classNames from "classnames";

import type { VDom } from "~/typings";

import * as S from "./Sticky.styles";

type Props = VDom.WithChildren<{
  mode: "top" | "bottom";
}>;

const Sticky = ({ mode, children }: Props) => {
  const className = classNames({
    [S.StickyTop]: mode === "top",
    [S.StickyBottom]: mode === "bottom",
  });

  return <div className={className}>{children}</div>;
};

export { Sticky };
