import { css } from "linaria";

import WavingHand from "~/assets/emojis/waving-hand.svg";
import Arrow from "~/assets/icons/arrow.svg";
import { Button, Header, Spacer, ContainerList, OrderList } from "~/components";
import type { Entity } from "~/typings";
import { pluralize } from "~/utils";

type Props = {
  store: Entity.Store;
  onStart: () => void;
  sortMode: Entity.SortMode;
  setSortMode: (mode: Entity.SortMode) => void;
};

const StepWaiting = ({ store, sortMode, onStart, setSortMode }: Props) => {
  const { orders, containers, deliveryMan = "" } = store;
  const hasStarted = containers.some((c) => c.collected);
  const orderCount = orders.length;
  const viewMode = hasStarted ? "info" : "none";

  return (
    <>
      <Header
        icon={<WavingHand />}
        title={`Hello ${deliveryMan} !`}
        description={
          <>
            <span>Vous avez</span>
            <b className="primary-fg">{` ${orderCount} ${pluralize("commande", orderCount)} `}</b>
            <span>à récupérer</span>
          </>
        }
      />
      <OrderList orders={store.orders} />
      <ContainerList
        viewMode={viewMode}
        sortMode={sortMode}
        containers={containers}
        setSortMode={setSortMode}
      />
      <Spacer />
      <Button
        size="large"
        onClick={onStart}
        className={css`
          position: sticky;
          bottom: 0;
        `}
      >
        <span>Récupérer les bacs</span>
        <Arrow />
      </Button>
    </>
  );
};

export { StepWaiting };
