import type { Backend, Entity } from "~/typings";

const getCollectStore = (data: Backend.GetTasks, collected: string[]) => {
  const orders: Entity.Order[] = [];
  const containers: Entity.Container[] = [];
  const deliveryMode = data?.deliveryMode;
  const deliveryMan = data?.deliveryMan.firstname;

  data?.orders.forEach((o) => {
    const order: Entity.Order = {
      id: o.id,
      ref: o.preparationReference,
      state: o.state,
      containers: [],
    };
    orders.push(order);
    o.containers.forEach((c) => {
      const container: Entity.Container = {
        order,
        id: c.id,
        ref: c.number,
        location: c.deliveryArea ?? "?",
        collected: c.collected || collected.includes(c.id),
      };
      order.containers.push(container);
      containers.push(container);
    });
  });

  return { orders, containers, deliveryMan, deliveryMode };
};

export { getCollectStore };
