import Ghost from "~/assets/emojis/ghost.svg";
import { Header } from "~/components";

/**
 * Display a basic information screen to the user when the list
 * of tasks can't be fetched, or if its structure is not correct
 */
const CollectError = () => {
  return (
    <Header
      icon={<Ghost />}
      title="Oups !"
      subtitle="Une erreur est survenue au chargement de la commande."
    />
  );
};

export { CollectError };
