import { Settings } from "~/settings";
import type { Barcode } from "~/typings";

/**
 * Pure Javascript implementation using ZXing
 * Slow but very high compatibility
 */
const VanillaBarcodeService: Barcode.Service = {
  name: "Javascript",
  isEnabled: () => {
    return Settings.Decoder.vanilla;
  },
  isAvailable: () => {
    return true;
  },
  loadScanner: async () => {
    // Import module
    const ZXing = await import("@zxing/library");
    ZXing.DecodeHintType.POSSIBLE_FORMATS;
    // Initialize scanner for QRCode and Code128
    const formats = [ZXing.BarcodeFormat.CODE_128, ZXing.BarcodeFormat.QR_CODE];
    const hints = new Map([[ZXing.DecodeHintType.POSSIBLE_FORMATS, formats]]);
    const reader = new ZXing.BrowserMultiFormatReader(hints);
    const canvas = document.createElement("canvas");
    // Normalize format name
    const getBarcodeFormat = (value: number): Barcode.Format => {
      if (value === ZXing.BarcodeFormat.CODE_128) return "Code128";
      if (value === ZXing.BarcodeFormat.QR_CODE) return "QRCode";
      return "Unknown";
    };
    // Generate scanner function
    return async (data: ImageData) => {
      if (canvas.height != data.height) canvas.height = data.height;
      if (canvas.width != data.width) canvas.width = data.width;
      canvas.getContext("2d")?.putImageData(data, 0, 0);
      const result = await reader.decodeFromImageUrl(canvas.toDataURL());
      const format = getBarcodeFormat(result.getBarcodeFormat());
      const value = result.getText();
      return [{ format, value }];
    };
  },
};

export { VanillaBarcodeService };
