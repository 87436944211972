import { Network } from "~/services/Network";
import type { Backend } from "~/typings";

/** Given a token, return the corresponding task */
const getTasks = async (token: string) => {
  return Network.get<Backend.GetTasks>(`/orders/${token}`);
};

/** Mark the given containers as collected */
const setCollected = (token: string, data: { orderId: string; containerId: string }[]) => {
  type Action = { action: "collect"; orderId: string; containerId: string };
  const items: Action[] = data.map((value) => ({ ...value, action: "collect" }));
  return Network.patch<unknown, { items: Action[] }>(`/orders/${token}`, { items });
};

/** Container for the api service */
const Api = { getTasks, setCollected };
export { Api };
