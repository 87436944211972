import Ghost from "~/assets/emojis/ghost.svg";
import { Header } from "~/components";

/**
 * Display a basic information screen to the user when the list
 * of tasks has no delivery man, and therefore can't be delivered
 */
const CollectInvalid = () => {
  return (
    <Header
      icon={<Ghost />}
      title="Oups !"
      subtitle="Aucun livreur n'est associé à cette commande."
    />
  );
};

export { CollectInvalid };
