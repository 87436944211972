import { css } from "linaria";

export const Wrapper = css`
  position: relative;
  padding: 0 0.5em;
  color: var(--foreground);
  background: var(--background);
  border: 1px solid var(--primary);

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    pointer-events: none;
    border-style: solid;
    transform: scale(0.8);
    border-width: 0.8em 0.5em 0 0.5em;
    border-color: var(--foreground) transparent transparent transparent;
  }
`;

export const Select = css`
  appearance: none;

  display: block;
  width: 100%;
  margin: 0.3em 0;
  padding: 0 1em 0 0;

  font-size: 1em;
  line-height: 1em;

  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  background: inherit;

  &::-ms-expand {
    display: none;
  }
`;
