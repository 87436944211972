import { useEffect, useState } from "react";

import CrossNormal from "~/assets/icons/cross-normal.svg";
import { Noise } from "~/components/Utils/Noise";
import { useAsync, useBarcodeScanner, useUserMediaStream } from "~/hooks";
import { Settings } from "~/settings";
import type { Barcode, VDom } from "~/typings";
import { getVideoFrameData } from "~/utils";

import * as S from "./CameraInput.styles";

// Force video container ratio to match scanner settings
const ratio = Settings.Scanner.height / Settings.Scanner.width;
const style = { paddingBottom: `${100 * ratio}%` };

type Props = {
  title?: VDom.Node;
  onClose?: () => void;
  onResults?: (results: Barcode.Result[]) => void;
};

const CameraInput = ({ title, onResults, onClose }: Props) => {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const { scanner, error: scannerError } = useBarcodeScanner();
  const { stream, error: streamError } = useUserMediaStream();
  const { data, error: detectError } = useAsync(
    () => {
      if (video == null || scanner == null) return null;
      return scanner(getVideoFrameData(video));
    },
    [scanner, video],
    200
  );

  const error = scannerError ?? streamError ?? detectError;
  const { videoWidth: width, videoHeight: height } = video ?? {};
  useEffect(() => void (video && (video.srcObject = stream)), [video, stream]);
  useEffect(() => void (onResults && data?.length && onResults(data)), [data, onResults]);

  return (
    <>
      <div className={S.Wrapper} style={style}>
        <video
          className={S.Video}
          width={width}
          height={height}
          ref={setVideo}
          autoPlay
          playsInline
        />
        {stream == null ? (
          <>
            <Noise />
            {error == null ? null : (
              <div className={S.Error}>
                Merci d’autoriser l’accès à la caméra dans les paramètres de votre navigateur
              </div>
            )}
          </>
        ) : (
          <>
            {title ? <div className={S.Title}>{title}</div> : null}
            <div className={S.Target}>
              <div />
              <div />
              <div />
              <div />
            </div>
          </>
        )}
        {onClose ? <CrossNormal className={S.Close} onClick={onClose} /> : null}
      </div>
    </>
  );
};

export { CameraInput };
