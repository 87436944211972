import useSWR from "swr";

/**
 * Resolve the given function asynchronously, the function
 * will be rerun every time the dependency array changes.
 * Polling can be configured using the 3rd parameter.
 */
const useAsync = <T, U>(fn: () => U | Promise<U>, deps: T[], poll = 0) => {
  const fetcher = async () => await fn();
  const config = { refreshInterval: poll };
  return useSWR<U, Error>(deps, fetcher, config);
};

export { useAsync };
