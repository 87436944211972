import { css } from "linaria";

export const Base = css`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0px none;

  svg {
    margin: 0 0.5em;
  }

  &:disabled {
    opacity: 0.8;
    filter: grayscale(0.8);
  }
`;
export const Size = {
  normal: css`
    font-size: 14px;
    padding: 8px 12px;
  `,
  large: css`
    width: 100%;
    padding: 32px;
    font-size: 20px;

    @media (min-width: 700px) {
      width: auto;
      margin: 32px auto;
      padding: 16px 32px;
    }
  `,
};

export const Mode = {
  primary: css`
    color: var(--foreground);
    background: var(--primary);
  `,
  secondary: css`
    color: var(--foreground);
    background: var(--background);
  `,
};
