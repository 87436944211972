import { css } from "linaria";

export const Wrapper = css`
  margin: auto;
  text-align: center;
`;

export const Version = css`
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  color: var(--neutral);
`;
