import classNames from "classnames";

import BoxNormal from "~/assets/icons/box-normal.svg";
import MapMarker from "~/assets/icons/map-marker.svg";
import ShoppingCart from "~/assets/icons/shopping-cart.svg";
import SuccessCircle from "~/assets/icons/success-circle.svg";
import WarningTriangle from "~/assets/icons/warning-triangle.svg";
import type { Entity } from "~/typings";

import * as S from "./ContainerRow.styles";

type Props = {
  container: Entity.Container;
  showStatus: boolean;
  showSeparator: boolean;
};

const ContainerRow = ({ container, showStatus, showSeparator }: Props) => {
  const isDone = container.collected;
  const className = classNames({ [S.Row]: true, [S.Separator]: showSeparator });

  return (
    <tr className={className}>
      <td>
        <MapMarker className={`${S.Icon} neutral-fg`} />
        <span>{container.location}</span>
      </td>
      <td>
        <BoxNormal className={`${S.Icon} neutral-fg`} />
        <span>{container.ref}</span>
      </td>
      <td>
        <ShoppingCart className={`${S.Icon} neutral-fg`} />
        <span>{container.order.ref}</span>
        {showStatus && (
          <>
            {isDone && <SuccessCircle className={`${S.Icon} ${S.IconStatus} success-fg`} />}
            {!isDone && <WarningTriangle className={`${S.Icon} ${S.IconStatus} warning-fg`} />}
          </>
        )}
      </td>
    </tr>
  );
};

export { ContainerRow };
