import { useEffect, useState } from "react";

import type { Barcode } from "~/typings";
import { getBarcodeService } from "~/utils";

/**
 * Detect the most compatible scanner service and return it.
 * If such service does not exists, an error is reported.
 */
const useBarcodeScanner = () => {
  type State = { scanner: Barcode.Scanner | null; error: Error | null };
  const [state, setState] = useState<State>({ scanner: null, error: null });

  useEffect(() => {
    getBarcodeService()
      .then((service) => service.loadScanner())
      .then((scanner) => void setState({ scanner, error: null }))
      .catch((error: Error) => void setState({ scanner: null, error }));
  }, []);

  return state;
};

export { useBarcodeScanner };
