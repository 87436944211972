import type { VDom } from "~/typings";

import * as S from "./Select.styles";

type Props = VDom.WithChildren<{
  value: string;
  onChange: (value: string) => void;
  className?: string;
}>;

const Select = ({ value, children, onChange, className }: Props) => {
  return (
    <div className={`${S.Wrapper} ${className ?? ""}`}>
      <select value={value} className={S.Select} onChange={(e) => onChange(e.currentTarget.value)}>
        {children}
      </select>
    </div>
  );
};

export { Select };
