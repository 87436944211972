import { Settings } from "~/settings";

/**
 * This fake context will be used if the application is unable
 * to get access to a native canvas context. Note that this will
 * throw at runtime in order to give some informations to the consumer
 */
const nocanvas = {
  drawImage() {
    throw new Error("Unable to access canvas context.");
  },
  getImageData() {
    throw new Error("Unable to access canvas context.");
  },
};

/**
 * This canvas will be used as the target for extracting frames from the video.
 * It is usually not thread-safe to rely on a singleton, but in this case, there
 * won't be any risk of race-condition. And it improves performances by a lot.
 */
const canvas = document.createElement("canvas");
canvas.width = Settings.Scanner.width;
canvas.height = Settings.Scanner.height;
const context = canvas.getContext("2d") ?? nocanvas;

/**
 * Extract the currently playing frame from the given video, the adjust it
 * to fit the configured scanner dimensions, and return the raw image data
 */
const getVideoFrameData = (video: HTMLVideoElement) => {
  // Compute the scaling to fit video in the canvas
  const scaleWidth = video.videoWidth / canvas.width;
  const scaleHeight = video.videoHeight / canvas.height;
  const scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;

  // Coordinate of the part of the video
  const w = canvas.width * scale;
  const h = canvas.height * scale;
  const x = (video.videoWidth - w) / 2;
  const y = (video.videoHeight - h) / 2;

  // Draw frame and return image data
  context.drawImage(video, x, y, w, h, 0, 0, canvas.width, canvas.height);
  return context.getImageData(0, 0, canvas.width, canvas.height) ?? null;
};

export { getVideoFrameData };
