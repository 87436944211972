import { css } from "linaria";

export const Wrapper = css`
  position: relative;
  background: var(--foreground);
`;

export const Content = css`
  height: 6px;
  min-width: 1px;
  max-width: 100%;
  background: var(--primary);
  transition: all 200ms ease;
`;

export const Tooltip = css`
  bottom: 18px;
  position: absolute;
  transition: all 200ms ease;
`;

export const Arrow = css`
  position: absolute;
  width: 0;
  height: 0;
  bottom: -6px;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 7px 6px 0 6px;
  border-color: var(--primary) transparent transparent transparent;
  transition: all 200ms ease;
`;

export const Label = css`
  padding: 6px 9px 3px 9px;
  font-size: 16px;
  line-height: 16px;
  color: var(--background);
  background: var(--primary);
`;
