/**
 * This is the recommended camera settings, not requirements.
 * These values will be used as a "hint" for the media api
 */
const Camera = {
  width: 1280,
  height: 720,
};

export { Camera };
