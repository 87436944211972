import { css } from "linaria";

export const Icon = css`
  margin-right: 8px;
  transform: translateY(0.125em);
`;

export const IconToggle = css`
  display: block;
  margin: 6px;
  opacity: 0.8;
  cursor: pointer;
  transition: transform 400ms ease;
`;

export const Tag = css`
  height: 18px;
  padding: 0 8px;
  font-size: 10px;
  line-height: 18px;
  border-radius: 9px;
  display: inline-block;
  text-transform: uppercase;
  color: var(--background);
  background: var(--foreground);
`;

export const Label = css`
  margin: 10px 0 0 0;
  font-size: 16px;
  font-weight: 300;
  color: var(--neutral);
`;

export const Details = css`
  display: grid;
  margin: 10px 0 20px 0;
  grid-gap: 5px 25px;
  grid-template-columns: repeat(auto-fill, minmax(5em, 1fr));

  > div {
    padding: 5px 0;
    font-size: 18px;
    border-bottom: 1px solid var(--neutral);
  }
`;
