import { css } from "linaria";

export const Wrapper = css`
  position: fixed;
  top: 100%;
  left: 50%;
  width: 100%;
  opacity: 0;
  transform: translate(-50%, 0);
  transition: all 400ms ease;

  @media (min-width: 700px) {
    width: auto;
  }
`;

export const Visible = css`
  opacity: 1;
  transform: translate(-50%, -100%);
`;

export const Content = css`
  padding: 10px 15px 15px 15px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 16px;
  color: var(--foreground);
  background: var(--primary);
  border-top: 4px solid rgba(0, 0, 0, 0.3);
`;
