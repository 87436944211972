/**
 * This object allows us to quickly enable or disable a specific
 * barcode decoder by rendering the related service inactive.
 */
const Decoder = {
  native: true,
  wasm: true,
  vanilla: true,
};

export { Decoder };
