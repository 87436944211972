import type { Entity } from "~/typings";

// Create a new collator instance for natural sort
const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

/** Compare two order using their preparation reference */
const compareOrdersByRef = (o1: Entity.Order, o2: Entity.Order) => {
  return collator.compare(`${o1.ref}`, `${o2.ref}`);
};

/** Compare two containers using their reference */
const compareContainersByRef = (c1: Entity.Container, c2: Entity.Container) => {
  return (
    collator.compare(`${c1.ref}`, `${c2.ref}`) ||
    collator.compare(`${c1.location}`, `${c2.location}`) ||
    collator.compare(`${c1.order.ref}`, `${c2.order.ref}`)
  );
};

/** Compare two containers using the related order as main key */
const compareContainersByOrderRef = (c1: Entity.Container, c2: Entity.Container) => {
  return (
    collator.compare(`${c1.order.ref}`, `${c2.order.ref}`) ||
    collator.compare(`${c1.location}`, `${c2.location}`) ||
    collator.compare(`${c1.ref}`, `${c2.ref}`)
  );
};

/** Compare two containers using their location as main key */
const compareContainersByLocation = (c1: Entity.Container, c2: Entity.Container) => {
  return (
    collator.compare(`${c1.location}`, `${c2.location}`) ||
    collator.compare(`${c1.order.ref}`, `${c2.order.ref}`) ||
    collator.compare(`${c1.ref}`, `${c2.ref}`)
  );
};

export {
  compareOrdersByRef,
  compareContainersByRef,
  compareContainersByOrderRef,
  compareContainersByLocation,
};
