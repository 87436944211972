import "~/assets/fonts/fonts.css";

import { css } from "linaria";

// Prepare elements
const root = document.createElement("div");
const html = document.documentElement;
const body = document.body;
body.appendChild(root);

// Application variables
html.classList.add(css`
  --desktop-width: 700px;
  --background: #1e1e1e;
  --foreground: #ffffff;
  --neutral: #787878;
  --primary: #5491ff;
  --success: #32d26e;
  --warning: #ff5046;
  --invalid: #ff3c3b;
`);

// Color helpers
html.classList.add(css`
  ${(() => {
    const vars = ["neutral", "primary", "success", "warning", "invalid"];
    const setFg = (key: string) => `.${key}-fg { color: var(--${key}) }`;
    const setBg = (key: string) => `.${key}-bg { background: var(--${key}) }`;
    return vars.map((key) => `${setFg(key)} ${setBg(key)}`).join("");
  })()}
`);

// Font settings
html.classList.add(css`
  font-size: 16px;
  font-stretch: 100%;
  letter-spacing: 0.4px;
  text-size-adjust: 100%;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Aspira, system-ui, Roboto, -apple-system, Segoe UI, sans-serif;
`);

// Minimalist reset
html.classList.add(css`
  :global() {
    html,
    body {
      margin: 0;
      padding: 0;
      height: 100%;
      color: var(--foreground);
      background: var(--background);
    }

    /** Since input don't cascade */
    input,
    button,
    textarea {
      font-size: inherit;
      font-family: inherit;
    }

    /* Force SVG size */
    svg {
      width: 1em;
      height: 1em;
    }

    /* Horizontal separator */
    hr {
      border: none;
      height: 2px;
      width: 100%;
      margin: 40px 0 0 0;
      background: var(--neutral);
    }
  }
`);

// Application container
root.classList.add(css`
  margin: auto;
  padding: 0;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  max-width: var(--desktop-width);
`);

// Define and export Theming service
const Theming = { getRoot: () => root };

export { Theming };
