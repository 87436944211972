import { css } from "linaria";

export const Wrapper = css`
  position: relative;
  overflow: hidden;
  display: block;
  height: 0;
  margin: 0;
  background: var(--background);
`;

export const Video = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Error = css`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  max-width: 70%;
  font-size: 14px;
  opacity: 0.8;
  text-align: center;
  border-radius: 4px;
  color: var(--foreground);
  background: var(--invalid);
  transform: translate(-50%, -50%);
`;

export const Close = css`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 32px;
  cursor: pointer;
`;

export const Title = css`
  position: absolute;
  top: 11.5%;
  left: 50%;
  padding: 11px 11px 8px 11px;
  transform: translate(-50%, -50%);
  color: #1e1e1e;
  background: #ffffff;
`;

export const Target = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 50%;
  opacity: 0.8;
  transform: translate(-50%, -50%);

  > div {
    width: 20px;
    height: 20px;
    position: absolute;
    border-top: 4px solid #ffffff;
    border-left: 4px solid #ffffff;

    &:nth-child(1) {
      top: 0;
      left: 0;
      transform: rotate(0deg);
    }

    &:nth-child(2) {
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }

    &:nth-child(4) {
      bottom: 0;
      left: 0;
      transform: rotate(270deg);
    }
  }
`;
