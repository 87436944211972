import Bowser from "bowser";

// Parse user agent to extract infos
const infos = Bowser.parse(window.navigator.userAgent);

// Prepare basic values
const Browser = {
  os: infos.os.name,
  type: infos.platform.type,
  browser: infos.browser.name,
};

export { Browser };
