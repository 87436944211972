import { useCallback, useEffect, useMemo, useState } from "react";

import { Loader } from "~/components";
import { useAsync } from "~/hooks";
import { Api } from "~/services";
import type { Entity } from "~/typings";
import {
  compareContainersByLocation,
  compareContainersByOrderRef,
  compareContainersByRef,
  compareOrdersByRef,
} from "~/utils";
import { getCollectStore } from "~/utils/collect/getCollectStore";

import { CollectError } from "./CollectError";
import { CollectInvalid } from "./CollectInvalid";
import { CollectProcessing } from "./CollectProcessing";

type Props = {
  token: string;
};

const Collect = ({ token }: Props) => {
  // Handle the list of already collected containers IDs
  const [collected, setCollected] = useState<string[]>([]);
  const collect = useCallback((values: string[]) => {
    setCollected((prev) => [...new Set([...prev, ...values])]);
  }, []);

  // Request data from backend
  const fetcher = useAsync(async () => {
    const getTasks = Api.getTasks(token);
    const minDelay = new Promise((r) => setTimeout(r, 1500));
    return Promise.all([getTasks, minDelay]).then(([value]) => value);
  }, [token]);

  // Extract variables from fetcher
  const error = fetcher.data?.error ?? fetcher.error;
  const result = fetcher.data?.data;
  const reload = fetcher.revalidate;

  // Generate sorted store
  const [sortMode, setSortMode] = useState<Entity.SortMode>("order");
  const store = useMemo(() => {
    // Generate store if possible
    if (result == null) return null;
    const output = getCollectStore(result, collected);
    // Sort orders and related containers
    output.orders.sort(compareOrdersByRef);
    output.orders.forEach((o) => o.containers.sort(compareContainersByLocation));
    // Sort containers depending on the choosen mode
    if (sortMode === "container") output.containers.sort(compareContainersByRef);
    else if (sortMode === "order") output.containers.sort(compareContainersByOrderRef);
    else if (sortMode === "location") output.containers.sort(compareContainersByLocation);
    return output;
  }, [sortMode, result, collected]);

  // Update backend every time the task list changes
  useEffect(() => {
    store?.orders.forEach((order) => {
      const payload = order.containers
        .filter((container) => container.collected)
        .map((container) => ({ orderId: order.id, containerId: container.id }));
      if (payload.length) Api.setCollected(token, payload).catch(() => null);
    });
  }, [store, token]);

  // Handle basic cases
  if (error) return <CollectError />;
  if (store == null) return <Loader />;
  if (store.deliveryMan == null) return <CollectInvalid />;
  return (
    <CollectProcessing
      store={store}
      reload={reload}
      collect={collect}
      sortMode={sortMode}
      setSortMode={setSortMode}
    />
  );
};

export { Collect };
