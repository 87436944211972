/**
 * Define the internal resolution used by the barcode scanners.
 * Video stream will be resized and cropped to fit inside and
 * then a screenshot will be feed to the Barcode service.
 */
const Scanner = {
  width: 720,
  height: 450,
};

export { Scanner };
