/**
 * From a numeric amount, return a string that includes
 * the amount and either `singular` or `plural` suffix.
 * `plural` is optional and default to `singular` + 's'
 */
const pluralize = (input: string | [string, string?], amount: number) => {
  const variants = typeof input === "string" ? [input] : input;
  const [singular, plural = `${singular}s`] = variants;
  return amount < 2 ? singular : plural;
};

export { pluralize };
