import { useEffect, useRef, useState } from "react";

import * as S from "./Noise.styles";

const Noise = () => {
  // State management
  const [noise, setNoise] = useState<ImageData | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  // Prepare variables
  const width = canvas?.clientWidth;
  const height = canvas?.clientHeight;
  const context = canvas?.getContext("2d");

  // Remember mount status
  const mountRef = useRef<boolean>(true);
  useEffect(() => {
    mountRef.current = true;
    return () => void (mountRef.current = false);
  }, []);

  // Generate noise when size change
  useEffect(() => {
    if (width == null || height == null) return;
    const image = new ImageData(width * 2, height * 2);
    for (let i = 0; i < image.data.length; i += 4) {
      image.data[i + 0] = Math.random() * 255;
      image.data[i + 1] = Math.random() * 255;
      image.data[i + 2] = Math.random() * 255;
      image.data[i + 3] = 255;
    }
    setNoise(image);
  }, [width, height]);

  // Start a loop that draw the noise
  useEffect(() => {
    // Animation loop
    let loop = true;
    const updateNoiseCanvasImageData = () => {
      if (width == null || height == null || context == null || noise == null) return;
      context.putImageData(noise, -width * Math.random(), -height * Math.random());
      if (loop) requestAnimationFrame(updateNoiseCanvasImageData);
    };
    // Start animation
    updateNoiseCanvasImageData();
    return () => void (loop = false);
  }, [width, height, noise, context]);

  return (
    <div className={S.Wrapper}>
      <canvas ref={setCanvas} width={canvas?.clientWidth} height={canvas?.clientHeight} />
    </div>
  );
};

export { Noise };
